/**
 * Overlay
 */

#crt-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.541176);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  transform: translateZ(0px);
  transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1200;
}